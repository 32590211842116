<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="手机号" prop="mobile">
        <a-input v-model="form.mobile" placeholder="请输入手机号" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="昵称" prop="nickName">
        <a-input v-model="form.nickName" placeholder="请输入昵称" />
      </a-form-model-item>
      <a-form-model-item label="最后登录时间" prop="lastLoginTime">
        <a-date-picker style="width: 100%" v-model="form.lastLoginTime" format="YYYY-MM-DD HH:mm:ss" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="最后登录IP" prop="lastLoginIp">
        <a-input v-model="form.lastLoginIp" placeholder="请输入最后登录IP" />
      </a-form-model-item>
      <a-form-model-item label="注册IP" prop="registerIp">
        <a-input v-model="form.registerIp" placeholder="请输入注册IP" />
      </a-form-model-item>
      <a-form-model-item label="最后登录城市" prop="lastLoginCity">
        <a-input v-model="form.lastLoginCity" placeholder="请输入城市(标准行政区划编码) 具体到市" />
      </a-form-model-item>
      <a-form-model-item label="城市" prop="city">
        <a-input v-model="form.city" placeholder="请输入城市(标准行政区划编码) 具体到市" />
      </a-form-model-item>
      <a-form-model-item label="用户签名" prop="sign">
        <a-input v-model="form.sign" placeholder="请输入用户签名" />
      </a-form-model-item>
      <!--      <a-form-model-item label="生日" prop="birthday" >-->
      <!--      </a-form-model-item>-->
      <!--      <a-form-model-item label="是否官方号" prop="isOfficial" >-->
      <!--        <a-input v-model="form.isOfficial" placeholder="请输入是否官方号 1:是,0:否" />-->
      <!--      </a-form-model-item>-->
      <a-form-model-item label="性别">
        <a-select v-model="form.gender" placeholder="请选择性别" allow-clear>
          <a-select-option v-for="(item, index) in this.customDict.GenderEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="头像" prop="avatar">
        <a-upload
          name="avatar"
          class="avatar-uploader"
          :multiple="true"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverAvatarUpload">
          <img
            v-if="form.avatar"
            :src="form.avatar"
            alt="avatar"
            style="height: 102px; width: 102px; border-radius: 50%"
          />
          <div v-else>
            <!--            <a-icon :type="loading ? 'loading' : 'plus'" />-->
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { addUser, getUser, updateUser } from '@/api/user/user'
import { generateFilePath, uploadObject } from '@/api/tool/alioss'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      previewVisible: false,
      previewImage: '',
      // 表单参数
      form: {
        id: null,
        mobile: null,
        nickName: null,
        lastLoginTime: null,
        lastLoginIp: null,
        registerIp: null,
        city: null,
        lastLoginCity: null,
        sign: null,
        birthday: null,
        isOfficial: null,
        gender: null,
        avatar: null,
        integral: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {

        isOfficial: [
          { required: true, message: '是否官方号 1:是,0:否不能为空', trigger: 'blur' }
        ],

        isDeleted: [
          { required: true, message: '逻辑删除标记，1：删除，0：未删除，默认0不能为空', trigger: 'blur' }
        ],

        version: [
          { required: true, message: '乐观锁不能为空', trigger: 'blur' }
        ]

      }
    }
  },
  filters: {},
  created() {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        mobile: null,
        nickName: null,
        lastLoginTime: null,
        lastLoginIp: null,
        registerIp: null,
        city: null,
        lastLoginCity: null,
        isPersonal: null,
        cameraPermissions: null,
        locationPermissions: null,
        messageNotification: null,
        sign: null,
        birthday: null,
        isOfficial: null,
        gender: null,
        avatar: null,
        integral: null,
        remark: null

      }
    },
    // oss 上传开始
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      const isImage = file.type.startsWith('image/')
      if (!isImage) {
        this.$message.error('请上传图片文件!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 10
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10MB!')
      }
      return isLt2M
    },
    // 上传用户头像覆盖默认的上传行为
    coverAvatarUpload(file, index) {
      console.log('获取文件对象', file.file)
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'userAvatar'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)
          _this.$set(_this.form, 'avatar', res)
          _this.$message.success('上传成功')
        })
      })
    },
    /**
     * 文件上传到oss返回url地址
     * @param { file } 文件对象
     */
    uploadAliYunOss(fileName, prefix) {
      // 获取文件后缀
      const suffix = fileName.substring(fileName.lastIndexOf('.'))
      // 生成文件名称
      return generateFilePath(prefix, suffix)
    },
    // oss 上传结束

    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getUser(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateUser(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUser(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
